import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var _excluded = ["hasPlaceholder"];
var __jsx = React.createElement;
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colorPalette, fonts } from '@/styles/styleGuide';
import Caret from '../../icons/Caret';
import ErrorMessage from './ErrorMessage';
import Label from './Label';
var defaultProps = {};
var Select = function Select(_ref) {
  var _ref2, _findValue$value;
  var _ref$hasPlaceholder = _ref.hasPlaceholder,
    hasPlaceholder = _ref$hasPlaceholder === void 0 ? false : _ref$hasPlaceholder,
    props = _objectWithoutProperties(_ref, _excluded);
  var findValue = props.choices.find(function (choice) {
    return choice.key === props.defaultValue;
  });
  return __jsx(FormSelectContainer, {
    "data-id": "fieldTypes_Select_FormSelectContainer"
  }, __jsx(Label, {
    name: props.name,
    content: props.title,
    required: props.required,
    mode: "label",
    "data-id": "fieldTypes_Select_Label"
  }), __jsx(SelectParent, {
    "data-id": "fieldTypes_Select_SelectParent"
  }, __jsx(FormSelect, _extends({}, props, {
    defaultValue: (_ref2 = (_findValue$value = findValue === null || findValue === void 0 ? void 0 : findValue.value) !== null && _findValue$value !== void 0 ? _findValue$value : findValue === null || findValue === void 0 ? void 0 : findValue.key) !== null && _ref2 !== void 0 ? _ref2 : undefined,
    "data-id": "fieldTypes_Select_FormSelect"
  }), __jsx("option", {
    disabled: true,
    value: "default",
    selected: hasPlaceholder,
    "data-id": "fieldTypes_Select_option"
  }, "- Maak een keuze -"), props.choices.map(function (option) {
    var _option$value, _option$disabled, _option$key;
    return __jsx("option", {
      value: (_option$value = option.value) !== null && _option$value !== void 0 ? _option$value : option.key,
      key: option.key,
      disabled: (_option$disabled = option.disabled) !== null && _option$disabled !== void 0 ? _option$disabled : false,
      "data-id": "fieldTypes_Select_option_5"
    }, String((_option$key = option.key) !== null && _option$key !== void 0 ? _option$key : option.value));
  })), __jsx(SelectCaret, {
    color: colorPalette.beige,
    direction: "down",
    "data-id": "fieldTypes_Select_SelectCaret"
  })), props.validationMessage && __jsx(ErrorMessage, {
    isValid: props.isValid,
    message: props.validationMessage,
    "data-id": "fieldTypes_Select_ErrorMessage"
  }));
};
Select.defaultProps = defaultProps;
export default Select;
var FormSelectContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n  min-width: 250rem;\n  min-height: 46rem;\n"])));
var SelectParent = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n"])));
var SelectCaret = styled(Caret)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: absolute;\n  right: 10rem;\n  margin: auto 0;\n  top: 0;\n  bottom: 0;\n"])));
var FormSelect = styled.select(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  z-index: 1;\n  width: 100%;\n  height: 46rem;\n  border: 0;\n  border-bottom: solid 1rem ", ";\n  border-radius: initial;\n  background: transparent;\n  appearance: none;\n  position: relative;\n  font-family: ", ";\n  font-style: italic;\n  font-size: 100%;\n  color: ", ";\n  padding-bottom: 1rem;\n  transition: border 0.15s ease-in-out;\n\n  &:focus {\n    outline: 0;\n    border-bottom: solid 2rem ", ";\n    padding-bottom: 0;\n\n    ~ span {\n      width: 2rem;\n      background: ", ";\n    }\n  }\n\n  &.filled {\n    font-style: normal;\n    color: ", ";\n  }\n"])), colorPalette.beige, fonts.primary, colorPalette.lightBeige, colorPalette.beige, colorPalette.beige, colorPalette.textGrey);