var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {
  "id": "M1-ppfBwxnM-FDwkuFl-D"
};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from '@sentry/nextjs';
var isDev = process.env.NODE_ENV === 'development' || process.env.NEXT_PUBLIC_DEBUG === 'true';
var DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
init({
  dsn: DSN,
  tracesSampleRate: isDev ? 1.0 : 0.1,
  environment: process.env.NEXT_PUBLIC_ENV,
  maxBreadcrumbs: 20,
  ignoreErrors: ['ResizeObserver loop limit exceeded']
  // beforeSend: (errorEvent) => {
  //   return errorEvent
  // },
  // beforeBreadcrumb: (breadcrumb) => {
  //   return breadcrumb
  // },
});