// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react'
import styled from 'styled-components'
import Button from '@/atoms/Button'
import TextButton from '@/atoms/TextButton'

const ButtonWrapperOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
`

const ButtonWrapperInner = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-top: 16rem;
  padding-bottom: 16rem;
  width: 100%;
`

type SwiperButtonProps = {
  disabled?: boolean
  text: string
  variant: 'next' | 'prev'
  buttonVariant?: 'default' | 'text'
}

export const SwiperButton = ({
  disabled = false,
  text,
  buttonVariant = 'default',
  variant,
}: SwiperButtonProps) => {
  const swiper = useSwiper()

  const onClick = () => (variant === 'next' ? swiper.slideNext() : swiper.slidePrev())

  if (buttonVariant === 'text') {
    return (
      <TextButton
        onClick={onClick}
        text={text}
        disabled={disabled}
      />
    )
  }

  return (
    <Button
      onClick={onClick}
      text={text}
      fullWidth={false}
      disabled={disabled}
    />
  )
}

type FormWizardNavigationProps = {
  hidePrevButton?: boolean
  disabledNextButton?: boolean
}

const FormWizardNavigation = ({
  hidePrevButton = false,
  disabledNextButton = false,
}: FormWizardNavigationProps) => {
  return (
    <ButtonWrapperOuter>
      <ButtonWrapperInner>
        <SwiperButton
          variant="next"
          text="Verder"
          disabled={disabledNextButton}
        />
        {!hidePrevButton && (
          <SwiperButton
            variant="prev"
            text="Terug"
          />
        )}
      </ButtonWrapperInner>
    </ButtonWrapperOuter>
  )
}

export default FormWizardNavigation
