import getInvalidFormFieldMessage from '@/processes/getInvalidFormFieldMessage'
import { useState } from 'react'

const VALIDITY_STATE: ValidityState = {
  badInput: false,
  customError: false,
  patternMismatch: false,
  rangeOverflow: false,
  rangeUnderflow: false,
  stepMismatch: false,
  tooLong: false,
  tooShort: false,
  typeMismatch: false,
  valid: true,
  valueMissing: false,
}

const useIsFormFieldValid = () => {
  const [validity, setValidity] = useState<ValidityState>(VALIDITY_STATE)
  if (validity.valid) {
    return {
      valid: true,
      setValidity,
    }
  }
  return {
    valid: false,
    message: getInvalidFormFieldMessage(validity),
    setValidity,
  }
}
export default useIsFormFieldValid
