// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react'
import { IForm } from '@/entities/legacyTypes'
import { useMutationRequest, useQueryRequest } from '@/hooks/requestHooks'
import useParams from '@/hooks/useParams'
import styled from 'styled-components'
import FormWizardNavigation, { SwiperButton } from '@/components/formWizard/FormWizardNavigation'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css'
import Title from '@/atoms/Title'
import Text from '@/atoms/Text'
import { clearModalState } from '@/redux/modal'
// eslint-disable-next-line import/no-cycle
import BBCodeHandler from '@/organisms/BBCodeHandler'
import { useCallback, useEffect, useState } from 'react'
import FormWizardFieldGenerator from '@/components/formWizard/FormWizardFieldGenerator'
import Button from '@/atoms/Button'
import { useDispatch } from 'react-redux'
import ProgressBar from '@/atoms/ProgressBar'
import Container from '@/atoms/Container'
import Box from '@/atoms/Box'

export type FormWizardSliderProps = {
  formId: string
}

export type InputValue = string | number | boolean | Array<string | number | boolean>
export type InputName = string

const ContentWrapper = styled.div<{ centered?: boolean; gap?: number }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ centered }) => (centered ? 'center' : '')};
  gap: ${({ gap = 0 }) => `${gap}rem`};
`
const FeedbackWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 32rem;
`

const Slider = styled(Swiper)`
  max-width: 100%;
`

const Slide = styled(SwiperSlide)`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  padding-top: 16rem;
`

const FormWizardSlider = ({ formId }: FormWizardSliderProps) => {
  const { projectSlug } = useParams()
  const [sliderIndex, setSliderIndex] = useState(0)
  const [values, setValues] = useState<Record<InputName, InputValue>>({})
  const dispatch = useDispatch()
  const {
    mutate: submitFormData,
    isError: submitFormIsError,
    isSuccess: submitFormIsSuccess,
  } = useMutationRequest('postFormSubmit')
  const { data, isError } = useQueryRequest<IForm>('getForm', undefined, {
    projectSlug,
    id: formId,
  })
  const [validSteps, setValidSteps] = useState<Set<number>>(new Set())

  const isLastSlide = sliderIndex === data?.fields[0].options.length

  const setStepIsValid = useCallback(
    (index: number) => (valid: boolean) =>
      setValidSteps((validStepsSet) => {
        if (valid) {
          validStepsSet.add(index)
        } else {
          validStepsSet.delete(index)
        }

        return new Set(validStepsSet)
      }),
    []
  )

  const handleInputChange = useCallback(
    (name: InputName) => (value: InputValue) => {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }))
    },
    []
  )

  useEffect(() => {
    data?.fields[0].options.forEach((option, index) => {
      setValidSteps((validStepsSet) => {
        if (!option.required || option?.populate) {
          validStepsSet.add(index)
        }

        return new Set(validStepsSet)
      })
      setValues((prevValues) => {
        if (!option?.populate) {
          return prevValues
        }
        return {
          ...prevValues,
          [option.name]: option.populate as InputValue,
        }
      })
    })
  }, [data?.fields])

  const onSubmit = useCallback(() => {
    submitFormData({
      params: {
        projectSlug,
        id: formId,
      },
      body: values,
    })
  }, [formId, projectSlug, submitFormData, values])

  if (!data || isError) {
    return (
      <Container>
        <Text>Formulier kan niet opgehaald worden</Text>
      </Container>
    )
  }

  if (submitFormIsError || submitFormIsSuccess) {
    return (
      <Container>
        <FeedbackWrapper>
          <Title>
            {submitFormIsError
              ? 'Er gaat iets fout bij het versturen van de gegevens'
              : 'Je gegevens zijn opgestuurd naar Homestudios'}
          </Title>
          <Button
            text="Sluiten"
            onClick={() => dispatch(clearModalState())}
            fullWidth={false}
          />
        </FeedbackWrapper>
      </Container>
    )
  }

  return (
    <Box>
      <ContentWrapper
        centered={isLastSlide}
        gap={isLastSlide ? 12 : 0}
      >
        <ProgressBar
          maxValue={data?.fields[0].options.length}
          currentValue={sliderIndex}
        />
        <Title>{isLastSlide ? 'Wilt u het formulier opsturen?' : data.title}</Title>
        {isLastSlide ? (
          <Text>{`Als u op 'opsturen' drukt dan worden uw gegevens opgestuurd naar Homestudios.`}</Text>
        ) : (
          <BBCodeHandler content={data.intro} />
        )}
      </ContentWrapper>
      <Slider onActiveIndexChange={(swiper) => setSliderIndex(swiper.activeIndex)}>
        {data?.fields[0].options.map((formField) => {
          return (
            <Slide key={formField.id}>
              <ContentWrapper>
                <FormWizardFieldGenerator
                  onChange={handleInputChange(formField.name)}
                  onValidation={setStepIsValid(sliderIndex)}
                  value={values[formField.name] ?? ''}
                  {...formField}
                />
              </ContentWrapper>
            </Slide>
          )
        })}
        <Slide>
          <ContentWrapper
            centered={isLastSlide}
            gap={isLastSlide ? 12 : 0}
          >
            <Button
              onClick={onSubmit}
              text="Opsturen"
              fullWidth={false}
            />
            <SwiperButton
              variant="prev"
              text="Ga terug"
              buttonVariant="text"
            />
          </ContentWrapper>
        </Slide>

        {!isLastSlide && (
          <FormWizardNavigation
            hidePrevButton={sliderIndex === 0}
            disabledNextButton={!validSteps.has(sliderIndex)}
          />
        )}
      </Slider>
    </Box>
  )
}

export default FormWizardSlider
