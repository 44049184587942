import React, { ChangeEvent } from 'react'
import ErrorMessage from './ErrorMessage'
import Checkbox from './Checkbox'
import BigFieldLabel from './BigFieldLabel'
import type { IFormFieldChoice } from '../../../../entities/types'

type CheckboxGroupProps = {
  name: string
  title: string
  onChange: <T extends HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(
    e: ChangeEvent<T>
  ) => void
  required?: boolean
  choices: IFormFieldChoice[]
  isValid?: boolean
  defaultValue?: unknown
  validationMessage?: string
}

const CheckboxGroup = ({
  title,
  choices,
  required = false,
  name,
  validationMessage,
  isValid,
  onChange,
  defaultValue,
}: CheckboxGroupProps) => {
  return (
    <>
      {title && (
        <BigFieldLabel
          label={title}
          required={required}
        />
      )}
      {choices.map(({ key, label, value, defaultChecked }) => (
        <Checkbox
          key={key}
          id={key}
          label={label ?? title ?? value}
          value={value}
          onChange={onChange}
          required={required}
          name={name}
          defaultChecked={defaultChecked || defaultValue === value}
        />
      ))}
      {validationMessage && (
        <ErrorMessage
          isValid={isValid}
          message={validationMessage}
        />
      )}
    </>
  )
}

export default CheckboxGroup
