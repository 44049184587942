import { fontStyles, fontWeight } from '@/styles/fonts'
import { IconProps } from '@/types/svgTypes'
import { FC } from 'react'
import BoxIcon, { BoxIconVariant } from '@/atoms/BoxIcon'
import styled from 'styled-components'
import { colors } from '@/styles/colors'
import { transitions } from '@/styles/shared'

export type TextButtonVariant =
  | 'gray40'
  | 'white'
  | 'brand'
  | 'brand2'
  | 'gray30'
  | 'gray25'
  | 'red'

type Size = 'sm' | 'md'

type Props = {
  onClick: () => void
  text: string
  iconBefore?: FC<IconProps>
  iconAfter?: FC<IconProps>
  variant?: TextButtonVariant
  size?: Size
  disabled?: boolean
}

const ICON_VARIANT_MAP: Record<TextButtonVariant, BoxIconVariant> = {
  gray40: 'transparent',
  white: 'white',
  brand: 'brand',
  brand2: 'brand',
  gray30: 'gray30',
  gray25: 'gray25',
  red: 'red',
}

const TEXT_COLOR_VARIANT_MAP: Record<TextButtonVariant, string> = {
  gray40: colors.gray40,
  white: colors.white,
  brand: colors.gray40,
  brand2: colors.brand,
  gray25: colors.gray25,
  gray30: colors.gray30,
  red: colors.red,
}

const TEXT_COLOR_HOVER_VARIANT_MAP: Record<TextButtonVariant, string> = {
  gray40: colors.brand,
  white: colors.brand,
  brand: colors.brand,
  brand2: colors.gray40,
  gray30: colors.gray40,
  gray25: colors.gray40,
  red: colors.gray40,
}

const WEIGHT_VARIANT_MAP: Record<TextButtonVariant, number> = {
  gray40: fontWeight.bold,
  white: fontWeight.bold,
  brand: fontWeight.bold,
  brand2: fontWeight.medium,
  gray30: fontWeight.medium,
  gray25: fontWeight.medium,
  red: fontWeight.medium,
}

const Button = styled.button<{ variant: TextButtonVariant; size: Size }>`
  ${({ size }) => fontStyles.body[size]}
  gap: ${({ size }) => (size === 'sm' ? 6 : 8)}rem;
  color: ${({ variant }) => TEXT_COLOR_VARIANT_MAP[variant]};
  font-weight: ${({ variant }) => WEIGHT_VARIANT_MAP[variant]};
  display: flex;
  align-items: center;
  width: max-content;
  ${transitions.hover}

  &:hover,
  &:focus,
  &:active {
    color: ${({ variant }) => TEXT_COLOR_HOVER_VARIANT_MAP[variant]};
  }
`

const TextButton: FC<Props> = ({
  onClick,
  text,
  size = 'md',
  iconBefore,
  iconAfter,
  variant = 'gray40',
  disabled = false,
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      size={size}
      disabled={disabled}
    >
      {!!iconBefore && (
        <BoxIcon
          icon={iconBefore}
          variant={ICON_VARIANT_MAP[variant]}
          size={size}
        />
      )}
      {text}
      {!!iconAfter && (
        <BoxIcon
          icon={iconAfter}
          variant={ICON_VARIANT_MAP[variant]}
          size={size}
        />
      )}
    </Button>
  )
}
export default TextButton
