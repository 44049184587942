import { useState } from 'react'
import styled from 'styled-components'
import Box from '@/atoms/Box'
import { AnimatePresence, motion } from 'framer-motion'
import ToggleButton from '@/atoms/ToggleButton'
// eslint-disable-next-line import/no-cycle
import Form from '@/components/Form'
import useParams from '@/hooks/useParams'

type Props = {
  buttonText?: string
  formId: string
  collapsed?: boolean
}

const SlideInOut = styled(motion.div)`
  overflow: hidden;
`

const CollapsableForm = ({ buttonText = 'Weergeven', formId, collapsed = true }: Props) => {
  const { projectSlug } = useParams()
  const [collapsableOpen, setCollapsableOpen] = useState(!collapsed)

  return (
    <Box marginBottom="xl">
      <Box
        paddingTop="xl"
        paddingBottom="xl"
      >
        <ToggleButton
          text={buttonText}
          onClick={() => setCollapsableOpen((v) => !v)}
          open={collapsableOpen}
        />
      </Box>
      <AnimatePresence>
        {collapsableOpen && (
          <SlideInOut
            animate={{ height: 'auto', opacity: 1 }}
            initial={{ height: 0, opacity: 0.2 }}
            exit={{ height: 0, opacity: 0.2 }}
            transition={{ duration: 0.8 }}
          >
            <Box paddingBottom="sm">
              <Form
                formId={formId}
                projectSlug={projectSlug}
              />
            </Box>
          </SlideInOut>
        )}
      </AnimatePresence>
    </Box>
  )
}

export default CollapsableForm
