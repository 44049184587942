import { colorPalette } from '@/styles/styleGuide'
import styled from 'styled-components'

type Colors = keyof typeof colorPalette

type Props = {
  maxValue: number
  currentValue: number
  backgroundColor?: Colors
  foregroundColor?: Colors
  size?: 'sm' | 'lg'
}

const Container = styled.div`
  width: 100%;
  margin-top: 16rem;
  margin-bottom: 16rem;
`

const BarBackground = styled.div<{ color: Colors; height: number }>`
  height: ${({ height }) => `${height}rem`};
  background-color: ${({ color }) => colorPalette[color]};
  position: relative;
  width: 100%;
`

const BarForeground = styled.div<{
  value: number
  height: number
  color: Colors
}>`
  height: ${({ height }) => `${height}rem`};
  width: ${({ value }) => `${value}%`};
  background-color: ${({ color }) => colorPalette[color]};
  top: 0;
  right: 0;
  transition: width 0.35s;
`

const ProgressBar = ({
  maxValue,
  currentValue,
  backgroundColor = 'lightGrey',
  foregroundColor = 'textGrey',
  size = 'sm',
}: Props) => {
  return (
    <Container>
      <BarBackground
        height={size === 'lg' ? 12 : 8}
        color={backgroundColor}
      >
        <BarForeground
          height={size === 'lg' ? 12 : 8}
          value={(100 / maxValue) * currentValue}
          color={foregroundColor}
        />
      </BarBackground>
    </Container>
  )
}

export default ProgressBar
