/**
 * Get the base URL for API requests, with optional API version number (defaults to 1.0)
 */
export const getBaseUrl = (apiVersion = '1.0') =>
  `${process.env.NEXT_PUBLIC_API_URL || ''}/api/${apiVersion}`

/**
 * Get all param names from the path (e.g. /:slug/:env/) and replace them with the respective values.
 * Unused params will be returned as a query string.
 * Will throw an error if there is a param in the path without a matching value.
 */
export const getPathWithParams = (path: string, params?: Record<string, string>) => {
  if (!params) {
    if (path.includes('/:')) {
      throw new Error(`Missing parameter for ${path}`)
    }
    return path
  }

  const pathParts = path.split('/')
  const pathPartsWithParams: string[] = []
  const usedParams: Set<string> = new Set()

  pathParts.forEach((part) => {
    if (part.startsWith(':')) {
      const paramName = part.substring(1)
      if (!params[paramName]) {
        throw new Error(`Missing parameter for ${path}: ${paramName}`)
      }
      pathPartsWithParams.push(params[paramName])
      usedParams.add(paramName)
    } else {
      pathPartsWithParams.push(part)
    }
  })

  const unusedParams = Object.entries(params).filter(([key]) => !usedParams.has(key))
  const queryString = unusedParams
    .map(([key, value]) => !!value && `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')

  const finalPath = pathPartsWithParams.join('/')
  return queryString ? `${finalPath}?${queryString}` : finalPath
}
