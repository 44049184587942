export enum PageType {
  article,
  houseType,
  footer,
  wishList,
  password,
  page,
  static,
}

export enum StaticPageSlug {
  app = 'app',
}

export enum StaticPageSlugForProject {
  'vergeet-mij' = 'vergeet-mij',
}
