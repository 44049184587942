// @flow

import React from 'react'
import styled from 'styled-components'
import ErrorMessage from './ErrorMessage'
import Radio from './Radio'
import BigFieldLabel from './BigFieldLabel'
import type { IFormFieldChoice } from '../../../../entities/types'

type RadioGroupProps = {
  name: string
  title: string
  onChange: () => void
  required?: boolean
  choices: Array<IFormFieldChoice>
  isValid?: boolean
  validationMessage?: string
  type: string
  defaultValue: string | number
}

const defaultProps = {}

const RadioGroupChoices = styled.div`
  display: flex;
  margin-right: -15rem;
  flex-direction: row;

  > div {
    margin-top: 10rem;
    margin-right: 30rem;
  }
`

const RadioGroup = ({
  title,
  choices = [],
  type,
  required = false,
  name,
  validationMessage,
  isValid,
  defaultValue,
  onChange,
}: RadioGroupProps) => {
  return (
    <>
      {title && (
        <BigFieldLabel
          label={title}
          required={required}
        />
      )}

      <RadioGroupChoices>
        {choices.length > 0 &&
          choices.map((option) => {
            return (
              <Radio
                key={option.key}
                id={option.key}
                name={name}
                label={option.key ?? option.value}
                type={type}
                value={option.value}
                defaultChecked={option.value === defaultValue}
                required={required}
                onChange={onChange}
                disabled={option.disabled ?? false}
              />
            )
          })}
      </RadioGroupChoices>

      {validationMessage && (
        <ErrorMessage
          isValid={isValid}
          message={validationMessage}
        />
      )}
    </>
  )
}

RadioGroup.defaultProps = defaultProps

export default RadioGroup
