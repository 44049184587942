import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { fonts, colorPalette, textStyles } from '../../../../styles/styleGuide';
import Label from './Label';
import ErrorMessage from './ErrorMessage';
var TextArea = function TextArea(_ref) {
  var id = _ref.id,
    name = _ref.name,
    _ref$required = _ref.required,
    required = _ref$required === void 0 ? false : _ref$required,
    defaultValue = _ref.defaultValue,
    color = _ref.color,
    className = _ref.className,
    onChange = _ref.onChange,
    title = _ref.title,
    isValid = _ref.isValid,
    validationMessage = _ref.validationMessage;
  return __jsx(TextAreaContainer, {
    "data-id": "fieldTypes_Textarea_TextAreaContainer"
  }, __jsx(TextAreaEl, {
    id: id,
    name: name,
    required: required,
    value: defaultValue,
    color: color,
    className: className,
    onKeyUp: onChange,
    onChange: onChange,
    onInput: onChange,
    "data-id": "fieldTypes_Textarea_TextAreaEl"
  }), __jsx(Label, {
    name: name,
    content: title,
    required: required,
    "data-id": "fieldTypes_Textarea_Label"
  }), __jsx(LabelBackground, {
    "data-id": "fieldTypes_Textarea_LabelBackground"
  }), validationMessage && __jsx(ErrorMessage, {
    isValid: isValid,
    message: validationMessage,
    "data-id": "fieldTypes_Textarea_ErrorMessage"
  }));
};
export default TextArea;
var TextAreaContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  min-width: 250rem;\n"])));
var TextAreaEl = styled.textarea(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  width: 100%;\n  outline: 0;\n  border: solid 1rem ", ";\n  border-radius: 0;\n  min-height: 200rem;\n  padding: 30rem 10rem 10rem 10rem;\n  font-family: ", ";\n  font-size: 100%;\n  color: ", ";\n  line-height: 26rem;\n  resize: none;\n  transition: border 0.15s ease-in-out;\n\n  + label {\n    ", " z-index: 2;\n    top: 12rem;\n    left: 1rem;\n    width: 99%;\n    padding: 5rem 5rem 10rem 10rem;\n  }\n\n  &:focus,\n  &.filled {\n    + label {\n      ", " transform: scale(0.75) translate(0, -12rem);\n    }\n  }\n"])), colorPalette.beige, fonts.primary, colorPalette.textGrey, textStyles.formLabelDefault, textStyles.formLabelAnimation);
var LabelBackground = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  position: absolute;\n  top: 1rem;\n  left: 1rem;\n  height: 40rem;\n  content: '';\n  width: 99%;\n  background: linear-gradient(\n    to bottom,\n    rgba(255, 255, 255, 1) 0%,\n    rgba(255, 255, 255, 1) 71%,\n    rgba(255, 255, 255, 0) 100%\n  );\n"])));