const getInvalidFormFieldMessage = (validity: ValidityState) => {
  if (validity.valid) {
    return undefined
  }

  const validationMessages: Record<keyof Omit<ValidityState, 'valid'>, string> = {
    valueMissing: 'Er is een waarde vereist.',
    badInput: 'De ingevoerde waarde is onjuist.',
    typeMismatch: 'Het type van de waarde is ongeldig.',
    patternMismatch: 'Het patroon van de waarde is ongeldig.',
    rangeOverflow: 'De waarde is te groot.',
    rangeUnderflow: 'De waarde is te klein.',
    stepMismatch: 'De waarde komt niet overeen met de verwachte stap.',
    tooLong: 'De waarde is te lang.',
    tooShort: 'De waarde is te kort.',
    customError: 'Er is een aangepaste fout opgetreden.',
  }

  const error = Object.entries(validationMessages).find(
    ([key]) => validity[key as keyof ValidityState]
  )

  return error ? error[1] : 'Onbekende fout.'
}

export default getInvalidFormFieldMessage
