import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { fromBreakpoint } from '../../../../styles/breakpoints';
import ErrorMessage from './ErrorMessage';
import Radio from './Radio';
import Checkbox from './Checkbox';
import BigFieldLabel from './BigFieldLabel';
import { FormCheckbox } from './Checkbox';
import { FormLabel } from './Label';
var defaultProps = {};
var ChoiceGroup = function ChoiceGroup(_ref) {
  var _choices$disabled;
  var title = _ref.title,
    _ref$choices = _ref.choices,
    choices = _ref$choices === void 0 ? [] : _ref$choices,
    type = _ref.type,
    _ref$required = _ref.required,
    required = _ref$required === void 0 ? false : _ref$required,
    name = _ref.name,
    validationMessage = _ref.validationMessage,
    isValid = _ref.isValid,
    onChange = _ref.onChange,
    defaultChecked = _ref.defaultChecked,
    defaultValue = _ref.defaultValue;
  return __jsx(Fragment, null, title && __jsx(BigFieldLabel, {
    label: title,
    required: required,
    "data-id": "fieldTypes_ChoiceGroup_BigFieldLabel"
  }), type === 'checkbox' ? __jsx(React.Fragment, null, Array.isArray(choices) && choices.length > 0 ? choices.map(function (choice) {
    var _choice$disabled;
    return __jsx(Checkbox, {
      id: choice.key,
      label: title ? title : choice.label || choice.value,
      value: choice.value,
      onChange: onChange,
      type: type,
      required: required,
      disabled: (_choice$disabled = choice.disabled) !== null && _choice$disabled !== void 0 ? _choice$disabled : false
      // Set target name as choicegroup if of type choicegroup. Used to handle onChange if type == choicegroup && name == checkbox
      ,
      name: name,
      defaultChecked: choice.defaultChecked || defaultChecked,
      "data-id": "fieldTypes_ChoiceGroup_Checkbox"
    });
  }) : __jsx(Checkbox, {
    id: choices.key,
    label: title ? title : choices.label || choices.value,
    value: choices.value,
    onChange: onChange,
    type: type,
    required: required,
    disabled: (_choices$disabled = choices.disabled) !== null && _choices$disabled !== void 0 ? _choices$disabled : false
    // Set target name as choicegroup if of type choicegroup. Used to handle onChange if type == choicegroup && name == checkbox
    ,
    name: name,
    defaultChecked: choices.defaultChecked || defaultChecked,
    "data-id": "fieldTypes_ChoiceGroup_Checkbox_2"
  })) :
  // Type is not checkbox, so it is a choicegroup
  __jsx(ChoiceGroupChoices, {
    "data-id": "fieldTypes_ChoiceGroup_ChoiceGroupChoices"
  }, choices.length > 0 && choices.map(function (option) {
    var _option$disabled, _option$label, _option$disabled2;
    var choiceProps = {
      id: option.key,
      label: option.label || option.value,
      value: option.value,
      onChange: onChange,
      type: type === 'choicegroup' ? 'checkbox' : type,
      required: required,
      name: name,
      defaultChecked: type === 'checkbox' ? option.defaultChecked || defaultChecked : defaultValue === option.key,
      disabled: (_option$disabled = option.disabled) !== null && _option$disabled !== void 0 ? _option$disabled : false
    };
    // The id choicegroup is needed so that the values are retrievable inside handleChange method of FormGenerator.js
    return __jsx(Option, {
      key: option.key,
      "data-id": "fieldTypes_ChoiceGroup_Option"
    }, type === 'choicegroup' ? __jsx(React.Fragment, null, __jsx(FormCheckbox, _extends({}, choiceProps, {
      "data-group-id": name,
      "data-id": "fieldTypes_ChoiceGroup_FormCheckbox"
    })), __jsx(FormLabel, {
      "for": option.key,
      "data-id": "fieldTypes_ChoiceGroup_FormLabel"
    }, option.value)) : __jsx(Radio, {
      id: option.key,
      name: name,
      label: (_option$label = option.label) !== null && _option$label !== void 0 ? _option$label : option.value,
      type: type,
      value: option.value,
      required: required,
      onChange: onChange,
      disabled: (_option$disabled2 = option.disabled) !== null && _option$disabled2 !== void 0 ? _option$disabled2 : false,
      "data-id": "fieldTypes_ChoiceGroup_Radio"
    }));
  })), validationMessage && __jsx(ErrorMessage, {
    isValid: isValid,
    message: validationMessage,
    "data-id": "fieldTypes_ChoiceGroup_ErrorMessage"
  }));
};
ChoiceGroup.defaultProps = defaultProps;
export default ChoiceGroup;
var ChoiceGroupChoices = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  margin-left: -15rem;\n  margin-right: -15rem;\n  flex-direction: column;\n\n  ", "\n\n  > div{\n    margin-top: 10rem;\n  }\n}\n"])), fromBreakpoint.md(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    flex-direction: ", ";\n    "])), function (props) {
  return props.rowMode ? 'column' : 'row';
}));
var Option = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  margin-left: 15rem;\n  margin-right: 15rem;\n  position: relative;\n"])));